import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import {Container, StartLoader, OnboardNav, } from "./Onboard2.components";
import Email from "../../components/containers/Email";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
import TopNav from "../../components/nav/TopNav";


// pages
import EmailPage from "./Onboard2_Email";
import PasswordPage from "./Onboard2_Password";
import LoginPage from "./Onboard2_Login";
import AgreementsPage from "./Onboard2_Agreements";
import BusinessPage from "./Onboard2_Business";
import LocationPage from "./Onboard2_Location";
import AccountPage from "./Onboard2_Account";
import AddressesPage from "./Onboard2_Addresses";
import EquiptmentPage from "./Onboard2_Equiptment";
import EmployeesPage from "./Onboard2_Employees";
import SuccessPage from "./Onboard2_Success";
import HoursPage from "./Onboard2_Hours";
import ServicesPage from "./Onboard2_Services";
import WelcomePage from "./Onboard2_Welcome";
import DashboardPage from "./Onboard2_Dashboard";
import ReviewPage from "./Onboard2_Review";


// export components
export {
    Container, 
    StartLoader,
    OnboardNav,
};



// --- config --- //

const DEBUG = true;
const DEMO = 'onboard2';

const PAGES = {
    email: {
        title: "Email", 
        Component: EmailPage, 
    },
    password: {
        title: "Password", 
        Component: PasswordPage, 
    },
    login: {
        title: "Login", 
        Component: LoginPage, 
    },
    welcome: {
        title: "Welcome", 
        Component: WelcomePage, 
    },
    agreements: {
        title: "Agreements", 
        Component: AgreementsPage, 
        isNav: true, 
    },
    business: {
        title: "Business", 
        Component: BusinessPage, 
        isNav: true, 
    },
    location: {
        title: "Location", 
        Component: LocationPage, 
        isNav: true, 
    },
    // account: {
    //     title: "Account", 
    //     Component: AccountPage, 
    //     isNav: true, 
    // },
    addresses: {
        title: "Addresses", 
        Component: AddressesPage, 
        isNav: true, 
    },
    equiptment: {
        title: "Equiptment", 
        Component: EquiptmentPage, 
        isNav: true, 
    },
    employees: {
        title: "Employees", 
        Component: EmployeesPage, 
        isNav: true, 
    },
    hours: {
        title: "Hours", 
        Component: HoursPage, 
        isNav: true, 
    },
    // services: {
    //     title: "Services", 
    //     Component: ServicesPage, 
    //     isNav: true, 
    // },
    review: {
        title: "Review", 
        Component: ReviewPage, 
        isNav: true, 
    },
    success: {
        title: "Success", 
        Component: SuccessPage, 
    },
    dashboard: {
        title: "Dashboard", 
        Component: DashboardPage, 
    },
}


// process data
const STEPS = Object.keys(PAGES); // i => name
let NAV_STEPS = []; // i => name
Object.keys(PAGES).forEach((name) => {
    if(PAGES[name].isNav) NAV_STEPS.push(name)
});



// --- functions --- //

export function useStepper(){
    const location = useLocation();
    const nav = useNavigate();
    const folders = location?.pathname?.split('/');

    // step name
    let stepName = folders.pop();
    if(stepName === '') stepName = folders.pop();
    if(stepName === DEMO) stepName = STEPS[0];

    // step index
    const stepIndex = STEPS.indexOf(stepName);
    const navIndex = NAV_STEPS.indexOf(stepName);

    // urls
    const baseUrl = '/demos/onboard2';
    const startUrl = baseUrl + '/' + STEPS[0];
    const nextUrl = (stepIndex < STEPS.length-1) ? baseUrl + '/' + STEPS[stepIndex+1] : baseUrl + '/' + STEPS[0];
    const backUrl = (stepIndex > 0) ? baseUrl + '/' + STEPS[stepIndex-1] : baseUrl + '/' + STEPS[0];

    // handlers
    const changeHandler = (e, locals, setter, ) => {
        if(!e?.target?.name) return;
        // console.log("VALUE: " + e.target.name + ": " + e.target.value);
        let obj = {};
        obj[`${e.target.name}`] = e.target.value;
        setter({...locals    , ...obj, });
    }

    const submitHandler = (dispatch, locals, url, types, reqs, ) => {
        // utils.submitLocals(dispatch, nav, );
        if(!url) url = nextUrl;

        // validate
        // --- WIP
        console.log('----- submitHandler: ', locals);

        // success
        dispatch({call: "SET_ONBOARD2", values: locals, });
        utils.nav(nav, nextUrl);
    }

    // return
    return {
        pages: PAGES, // name => object
        steps: STEPS, // i => name
        navSteps: NAV_STEPS, // i => name
        stepName, 
        stepIndex, 
        navIndex,
        location, 
        baseUrl: baseUrl, 
        startUrl: startUrl,
        nextUrl: nextUrl,
        backUrl: backUrl,
        changeHandler,
        submitHandler,
    };
}








// --- components --- //

/*
function OnboardNav_Item222({item, name, index, stepper, }){
    const nav = useNavigate();
    const {title, navTitle, } = item;
    const {stepIndex, steps, navSteps, baseUrl, navIndex, } = stepper;
    const label = navTitle || title;
    const first = !index;
    const last = index == (navSteps.length - 1);

    // status
    let status = "incomplete";
    if(index == navIndex) status = "current";
    else if(index < navIndex) status = "complete";

    const url = (status !== "incomplete") ? `${baseUrl}/${name}` : undefined;
    const onClick = () => {
        if(url) utils.nav(nav, url);
    }

    // render
    const css = utils.classNames("lds-onboard2-topnav-item", `is-status-${status}`, `is-index-${index}`, url && `is-clickable`, first && `is-first`, last && `is-last`);
    return (
        <div className={css} onClick={onClick}>
            <Icon type={"progress-" + status} size="lg" />
            <div className="lds-onboard2-topnav-line">
                {!first && <div className="lds-onboard2-topnav-leftline" />}
                {!last && <div className="lds-onboard2-topnav-rightline" />}
            </div>
            <div >{label}</div>
        </div>
    );
}

function OnboardNav222(){
    const stepper = useStepper();
    const {pages, stepIndex, navSteps, } = stepper;
    const {onboard2, } = useGlobals();

    // const [items, setItems] = React.useState([]);
    // React.useEffect(() => {
    //     let arr = 
    // }, []);

   //  const items = 

    // return
    return (
        <div className="lds-onboard2-topnav">
            {navSteps.map((name, index) => <OnboardNav_Item key={index} name={name} item={pages[name]} index={index} stepper={stepper} />)}
        </div>
    );
}




export function StartLoader222(props){
    let {height, duration, debug, onComplete, } = props;

    if(!duration) duration = 1000;


    // render
    return  <Loader type="preload" color="white" onComplete={onComplete} duration={duration} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
}
*/



// export function ProgressBar(props){
//     const {step, } = props;
//     const css = utils.classNames("lds-demos-progressbar", step && `is-step-${step}`);
//     return <div className={css} />;
// }



// export function Container222(props){
//     const {name, children, title, description, icon,  } = props;
//     const dispatch = useDispatch();
//     const nav = useNavigate();
//     const [loaded, setLoaded] = React.useState(false);
//     const {stepName, startUrl, } = useStepper();

//     // handlers
//     const onLoaderComplete = () => setLoaded(true);
//     const onLogoClick = () => {
//         dispatch({call: "RESET_ONBOARD2", }); // --- WIP
//         utils.nav(nav, startUrl);
//     }

//     // render
//     const css = utils.classNames("lds-onboard1", `is-step-${stepName}`, `is-name-${stepName}`);
//     return (
//         <div className={css}>
//             <TopNav.Basic />
//             <Sprite.Button debug={0} to222={startUrl} onClick={onLogoClick} abs style={{zIndex: 10, width: 200, height: 80, position: 'fixed', }} />

//             <StartLoader duration={1000} height={800} onComplete={onLoaderComplete} />

//             <div className="lds-onboard1-top">
//                 {icon && <OnboardNav />}
//             </div>

//             {icon && <div className="lds-onboard2-page-icon" />}
//             {icon && <div className="lds-onboard2-page-title">{title}</div>}
//             {icon && <div className="lds-onboard2-page-line" />}

//             {/* <div className="lds-onboard1-content" style={{opacity: loaded?1:0, height: loaded?'auto':600, }}>{children}</div> */}
//             <div className="lds-onboard1-content" style={loaded ? {} : {opacity: 0, height: 800, overflow: 'hidden', }}>{children}</div>
//         </div>
//     );
// }



// --- OLD...

// export function EndLoader(props){
//     let {next, ending, height, duration, debug, } = props;
//     const nav = useNavigate();
//     const onComplete = () => nav(next);
    
//     // render
//     if(!ending) return;
//     return <Loader type="preload" color="translucent" onComplete={onComplete} duration={duration||800} debug={debug} style={{top: 0, left: 0, width: '100%', height: height||800,  }} />;
// }



// export function ButtonButtons(props){
//     const {style, backUrl, nextUrl, } = props;

//     return (
//         <Sprite.Container debug={1} style={style}>
//             {backUrl && <Sprite.Button debug={1} to={backUrl} style={{width: 200, }} />}
//             {nextUrl && <Sprite.Button debug={1} to={nextUrl} style={{width: 200, left: 200, }} />}
//         </Sprite.Container>
//     );
// }

// export function Container222(props){
//     const {name, children, title, description, icon, step,  } = props;
//     const dispatch = useDispatch();
//     // const {onboard1, } = useGlobals();
//     // const {step, } = onboard1;
//     const location = useLocation();
//     // const step = 2;

//     React.useEffect(() => {
//         const urlPath = location?.pathname;
//         const stepName = LOCALS.stepName = urlPath?.split('/').pop() || "";
//         const step = LOCALS.step = STEPS.indexOf(stepName);
//         // const step = steps.find(stepName);

//         // console.log("========== STEP: stepName: " + stepName + ", step: " + step + ", urlPath: " + urlPath);

//         // dispatch({call: "SET_ONBOARD1_VALUES", values: {step: 1, }, });
//     }, []);

//     // render
//     const css = utils.classNames("lds-onboard1", `is-step-${LOCALS.step}`, `is-name-${name}`);
//     return (
//         <div className={css}>
//             <TopNav.Basic />
//             <div className="lds-onboard1-top">
//                 {!isNaN(step) && <ProgressBar step={step} />}
//                 {icon && <div className={`lds-onboard1-icon is-type-${icon}`} />}
//                 {title && <div className="lds-onboard1-title">{title}</div>}
//                 {description && <div className="lds-onboard1-description">{description}</div>}
//             </div>
//             <div className="lds-onboard1-content">{children}</div>
//         </div>
//     );
// }


// export function StepButton222(props){
//     const {style, step, debug, } = props;
//     const dispatch = useDispatch();
//     const onClick = () => dispatch({call: "SET_ONBOARD1_STEP", value: step, });
//     // onClick333={onClick} 

//     // render
//     return <Sprite.Button to={`/demos/onboard1/${step}`} debug={debug} style={style} />;
// }


// export function StepButton(props){
//     const {style, debug, offset, index, } = props;
//     const dispatch = useDispatch();

//     let {stepName, stepIndex, } = useStepper();


//     let step = stepName;
//     if(index) step = STEPS[index];
//     else if(offset) step = STEPS.indexOf(1); // stepIndex + offset

//     // const step2 = offset ? offset.indexOf(stepIndex + offset) : step;
//     // if(offset) offset.indexOf(stepIndex + offset);

//     // handlers
//    //  const onClick = () => dispatch({call: "SET_ONBOARD1_STEP", value: step, });

//    console.log(`/demos/${DEMO}/${stepName}`);

//     // return
//     return <Sprite.Button to={`/demos/${DEMO}/${stepName}`} debug={debug} style={style} />;
// }


// export function ButtonGroup(props){
//     const {style, nextOnly, debug, } = props;
//     const location = useLocation();
//     // const step = getStepParam(location);
//     const {stepName} = useStepper();
//     const step = stepName;
//     // const {onboard1, } = useGlobals();
//     // const {step, } = onboard1;

//     const css = "lds-onboard1-buttons";

//     if(nextOnly) return (
//         <Sprite.Container debug={debug} style={style} className={css} >
//             <StepButton step={step+1} debug={debug} style={{width: '100%', }} />
//         </Sprite.Container>
//     );

//     // render
//     return (
//         <Sprite.Container debug={debug} style={style} className={css} >
//             <StepButton step={step-1} debug={debug} style={{width: '50%', }} />
//             <StepButton step={step+1} debug={debug} style={{width: '50%', left: '50%', }} />
//         </Sprite.Container>
//     );
// }
