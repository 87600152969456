import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobals, useDispatch } from '../../app/context';
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
import Email from "../../components/containers/Email";
import {EndLoader, StartLoader, ButtonGroup, StepButton, Container, ProgressBar, useStepper, steps, } from "./Onboard2.config";
import "./Onboard2.scss";

const DEBUG = 0;


export default function Page(props){
    const dispatch = useDispatch();
    const {onboard2, } = useGlobals();
    const {nextUrl, backUrl, changeHandler, submitHandler, } = useStepper();
    const [locals, setLocals] = React.useState({...onboard2, });

    const onChange = (e) => changeHandler(e, locals, setLocals);
    const onSubmit = (e) => submitHandler(dispatch, locals, );


    // Amplitude

    // render
    return (
        <Container icon="location" title="Location" description="Please confirm or fill-in the following info.">

            <Sprite.Container debug={0} className="lds-onboard2-location">


                <Input sprite debug={1} label="Username" name="username" value={locals.username} onChange={onChange} style={{top: 96, left: 10, width: 300, }} />


                <Sprite.Container style={{top: 1485, left: 74, }}>
                    <Sprite.Button debug={1} to={backUrl} style={{top: 0, width: 170, }} />
                    <Sprite.Button debug={1} onClick={onSubmit} style={{left: 180, width: 170, }} />
                </Sprite.Container> 
                
            </Sprite.Container>

        </Container>
    );
}
